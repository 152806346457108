import React from "react";

import AdminLayout from "../../components/AdminLayout";
import ErrorMessage from "../../components/ErrorMessage";
import { spotifyAuthUrl, syncWeddingPlaylist } from "../../ajax";

export default class Manage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false,
      user: null,
      error: null,
      tab: "spotify",
    };
  }

  componentDidMount() {
    if (window && window.netlifyIdentity) {
      window.netlifyIdentity.on("init", (user) => {
        this.setState({
          user: user || window.netlifyIdentity.currentUser(),
          loggedIn: !!user,
        });
      });

      window.netlifyIdentity.on("login", (user) =>
        this.setState({ user, loggedIn: true })
      );

      window.netlifyIdentity.on("logout", (user) =>
        this.setState({ user: null, loggedIn: false })
      );

      window.netlifyIdentity.init();
    }
  }

  componentWillUnmount() {
    if (window && window.netlifyIdentity) {
      window.netlifyIdentity.off("init");
      window.netlifyIdentity.off("login");
      window.netlifyIdentity.off("logout");
    }
  }

  handleClick = (e) => {
    const user =
      window && window.netlifyIdentity && window.netlifyIdentity.currentUser();

    if (user) {
      this.setState({
        user: user,
        loggedIn: !!user,
      });
    } else {
      window.netlifyIdentity.open();
    }
  };

  handleClickTab = (tab) => (e) => {
    e.preventDefault();

    this.setState({ tab });
  };

  renderTab() {
    const { tab } = this.state;

    switch (tab) {
      case "spotify":
        return (
          <section className="section">
            <div className="container is-fluid">
              <div className="buttons">
                <button
                  className="button"
                  onClick={() => {
                    spotifyAuthUrl().then((response) => {
                      window.open(response.authorizeURL, "_blank");
                    });
                  }}
                >
                  Connect
                </button>
                <button
                  className="button"
                  onClick={(e) => {
                    const accessToken = localStorage.getItem(
                      "spotify_access_token"
                    );

                    syncWeddingPlaylist(accessToken).then((response) => {
                      console.log(response);
                    });
                  }}
                >
                  Sync wedding playlist
                </button>
              </div>
            </div>
          </section>
        );
      default:
        return null;
    }
  }

  render() {
    const { loggedIn, tab, error } = this.state;

    return (
      <AdminLayout>
        {loggedIn ? (
          <div>
            <section className="section">
              <div className="container">
                <h1 className="title">Admin</h1>
              </div>
            </section>
            <div className="container is-fluid">
              <div className="tabs mb-0">
                <ul>
                  <li className={tab === "spotify" ? "is-active" : null}>
                    <a href="#" onClick={this.handleClickTab("spotify")}>
                      Spotify
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {error && (
              <ErrorMessage>
                <div className="level">
                  <p>{error.message}</p>
                </div>
              </ErrorMessage>
            )}
            {this.renderTab()}
          </div>
        ) : (
          <section className="section">
            <div className="container">
              <button className="button" onClick={this.handleClick}>
                Login
              </button>
            </div>
          </section>
        )}
      </AdminLayout>
    );
  }
}
