import React from "react";

class ErrorMessage extends React.Component {
  render() {
    return (
      <article className="message is-danger">
        <div className="message-header">
          <p>Error</p>
        </div>
        <div className="message-body">{this.props.children}</div>
      </article>
    );
  }
}

export default ErrorMessage;
